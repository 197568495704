import Dropdown from "../../../components/FormFields/Dropdown";
import CheckBox from "../../../components/FormFields/CheckBox";
import TextField from "../../../components/FormFields/Textfield";
import Radio from "../../../components/FormFields/Radio";
import DateField from "../../../components/FormFields/DateField";
import Signature from "../../../components/FormFields/Signature";
import FileUpload from "../../../components/FormFields/FileUpload";
import Modal from "../../../components/Modal/Modal";
import Button, { ColorStyle } from "../../../components/Button/Button";
import NoticeBanner from "../../../components/NoticeBanner/NoticeBanner";
import * as Yup from "yup";
import FormGrid from "../../../components/FormGrid/FormGrid";
import { useFormikContext } from "formik";
import { useEffect } from "react";
import { getNestedValue } from "../../../utils/getNestedValue";
import { useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { USStates } from "../../HealthCareCoverageForm/FormSchema/formInitialValues";

const requiredWarning = "This field is required.";

export const schema = {
	values: {
		newborn: {
			lastName: "",
			firstName: "",
			middleInitial: "",
			birthDate: "",
			gender: "",
		},
		enrollee: {
			lastName: "",
			firstName: "",
			middleInitial: "",
			birthDate: "",
			nmmipID: "",
			email: "",
			homePhone: "",
			workPhone: "",
			cellPhone: "",
		},
		residence: {
			address: "",
			city: "",
			state: "New Mexico",
			zipCode: "",
		},
		mailing: {
			address: "",
			city: "",
			state: "",
			zipCode: "",
		},
		isNewbornResidentOfNM: "",
	},
	validation: Yup.object().shape({
		newborn: Yup.object().shape({
			lastName: Yup.string().required(requiredWarning),
			firstName: Yup.string().required(requiredWarning),
			birthDate: Yup.string().required(requiredWarning),
			gender: Yup.string().required(requiredWarning),
		}),
		enrollee: Yup.object().shape({
			lastName: Yup.string().required(requiredWarning),
			firstName: Yup.string().required(requiredWarning),
			birthDate: Yup.string().required(requiredWarning),
			nmmipID: Yup.string().required(requiredWarning),
			email: Yup.string().email("Invalid email"),
			// Custom validation to ensure at least one phone number is provided
			atLeastOnePhoneNumberIsRequired: Yup.mixed().test({
				name: "atLeastOnePhoneNumber",
				message: "At least one phone number is required.",
				test(value, ctx) {
					const { homePhone, workPhone, cellPhone } = ctx.parent;
					return homePhone || workPhone || cellPhone;
				},
			}),
		}),
		residence: Yup.object().shape({
			address: Yup.string().required(requiredWarning),
			city: Yup.string().required(requiredWarning),
			state: Yup.string().required(requiredWarning),
			zipCode: Yup.string().required(requiredWarning),
		}),
		isNewbornResidentOfNM: Yup.string()
			.oneOf(["Yes"], "You must be a New Mexico resident to apply.")
			.required(requiredWarning),
	}),
};

const ApplicantInfo = () => {
	const { values, errors } = useFormikContext<any>();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isNewbornModalOpen, setIsNewbornModalOpen] = useState(false);
	const isResidenceStateNM = values["residence"]["state"];
	const isNewbornResidentOfNM = values["isNewbornResidentOfNM"];

	const phoneErr = (errors as PartialErrors).enrollee
		?.atLeastOnePhoneNumberIsRequired;

	useEffect(() => {
		if (isNewbornResidentOfNM && isNewbornResidentOfNM === "No") {
			setIsNewbornModalOpen(true);
		} else {
			setIsNewbornModalOpen(false);
		}
	}, [isNewbornResidentOfNM]);

	useEffect(() => {
		if (isResidenceStateNM && isResidenceStateNM !== "New Mexico") {
			setIsModalOpen(true);
		} else {
			setIsModalOpen(false);
		}
	}, [isResidenceStateNM]);

	return (
		<>
			<p>
				To select your birth year, find the date in the calendars OR click the
				current month and year at the top of the calendar, then click the year
				to view by year. Clicking a third time will allow you to first select a
				decade.
			</p>

			<h2>Newborn's Information</h2>

			<FormGrid>
				<TextField
					type="text"
					name="newborn.lastName"
					label="Newborn's Last Name"
					required
				/>
				<TextField
					type="text"
					name="newborn.firstName"
					label="Newborn's First Name"
					required
				/>
				<TextField
					className="sm:!max-w-[175px]"
					type="text"
					name="newborn.middleInitial"
					label="Middle Initial"
				/>
			</FormGrid>
			<FormGrid>
				<DateField
					name="newborn.birthDate"
					label="Newborn's Birth Date (MM/DD/YYYY)"
					placeholder="Newborn's Birth Date (MM/DD/YYYY)"
					required
					mobileExtraPadding
				/>
				<Dropdown
					name="newborn.gender"
					label="Newborn's Gender"
					options={[{ label: "Female" }, { label: "Male" }]}
					required
				/>
			</FormGrid>

			<h2>NMMIP Enrollee's Information</h2>

			<FormGrid>
				<TextField
					type="text"
					name="enrollee.lastName"
					label="NMMIP Enrollee's Last Name"
					required
				/>
				<TextField
					type="text"
					name="enrollee.firstName"
					label="NMMIP Enrollee's First Name"
					required
				/>
				<TextField
					className="sm:!max-w-[175px]"
					type="text"
					name="enrollee.middleInitial"
					label="Middle Initial"
				/>
			</FormGrid>
			<FormGrid>
				<DateField
					className="!basis-[275px]"
					name="enrollee.birthDate"
					label="NMMIP Enrollee's Birth Date (MM/DD/YYYY)"
					placeholder="NMMIP Enrollee's Birth Date (MM/DD/YYYY)"
					required
					mobileExtraPadding
				/>
				<TextField
					className="!basis-[275px]"
					type="text"
					name="enrollee.nmmipID"
					label="NMMIP Enrollee's ID Number"
					required
				/>
			</FormGrid>

			<h2>Contact Information</h2>
			<FormGrid>
				<TextField
					className="!basis-[400px]"
					type="text"
					name="residence.address"
					label="Residence Address (Physical address required)"
					required
					autoComplete="street-address"
					mobileExtraPadding
				/>
				<TextField
					className="!xs:max-w-[200px]"
					type="text"
					name="residence.city"
					label="City"
					required
				/>
				<Dropdown
					className="!xs:max-w-[200px]"
					name="residence.state"
					label="State"
					options={USStates}
					required
				/>
				<TextField
					className="!xs:max-w-[200px]"
					type="text"
					name="residence.zipCode"
					label="Zip Code"
					required
					maxLength={5}
				/>
			</FormGrid>

			<FormGrid>
				<TextField
					className="!basis-[400px]"
					type="text"
					name="mailing.address"
					label="Mailing Address (if different than Residence)"
					autoComplete="off"
					mobileExtraPadding
				/>
				<TextField
					type="text"
					name="mailing.city"
					label="City"
					autoComplete="off"
				/>
				<Dropdown name="mailing.state" label="State" options={USStates} />
				<TextField
					type="text"
					name="mailing.zipCode"
					label="Zip Code"
					maxLength={5}
					autoComplete="off"
				/>
			</FormGrid>

			<FormGrid>
				<TextField
					className="!basis-[275px]"
					type="email"
					name="enrollee.email"
					label="Email Address"
				/>
				<TextField
					type="text"
					name="enrollee.homePhone"
					label="Home Phone"
					error={phoneErr}
				/>
				<TextField
					type="text"
					name="enrollee.workPhone"
					label="Work Phone"
					error={phoneErr}
				/>
				<TextField
					type="text"
					name="enrollee.cellPhone"
					label="Cell Phone"
					error={phoneErr}
				/>
			</FormGrid>

			<Radio
				className="!max-w-[600px] mt-[1rem]"
				name="isNewbornResidentOfNM"
				label="The newborn will be a resident of the state of New Mexico."
				options={[{ label: "Yes" }, { label: "No" }]}
				required
			/>

			<Modal
				modalLabel="Residency Eligibility"
				open={isNewbornModalOpen}
				setOpen={setIsNewbornModalOpen}
			>
				<NoticeBanner bannerstyle={ColorStyle.Secondary}>
					You have indicated that the newborn is{" "}
					<span className="underline">not</span> a resident of the State of New
					Mexico and therefore do not qualify for health insurance under the
					Pool's eligbility guidelines. The newborn{" "}
					<span className="underline">must</span> be a New Mexico resident to
					apply.
				</NoticeBanner>
				<Button
					className="mx-auto"
					type="button"
					onClick={() => setIsNewbornModalOpen(false)}
				>
					<FaArrowLeft />
					Back
				</Button>
			</Modal>

			<Modal
				modalLabel="Residency Eligibility"
				open={isModalOpen}
				setOpen={setIsModalOpen}
			>
				<NoticeBanner bannerstyle={ColorStyle.Secondary}>
					You have indicated that you are <span className="underline">not</span>{" "}
					a resident of the State of New Mexico and therefore do not qualify for
					health insurance under NMMIP's eligbility guidelines. You{" "}
					<span className="underline">must</span> be a New Mexico resident to
					apply.
				</NoticeBanner>
				<Button
					className="mx-auto"
					type="button"
					onClick={() => setIsModalOpen(false)}
				>
					<FaArrowLeft />
					Back
				</Button>
			</Modal>
		</>
	);
};

export default ApplicantInfo;

interface PartialErrors {
	enrollee?: {
		atLeastOnePhoneNumberIsRequired?: string;
	};
}
