import Intro from "../SectionIntro/Intro";
import Start from "../SectionIntro/Start";

import ApplicantInfo from "../Section1/ApplicantInfo";
import BasicInfo from "../Section1/BasicInfo";
import PremiumPaymentCert from "../Section1/PremiumPaymentCert";

import Submission from "../Submission/Submission";
import Household from "../Section2/Household";
import Magi from "../Section2/Magi";
import OtherIncome from "../Section3/OtherIncome";
import AffidavitA from "../Section4/AffidavitA";
import AffidavitB from "../Section4/AffidavitB";
import AffidavitC from "../Section5/AffidavitC";
import GrossIncome from "../Section2/GrossIncome";
import Documentation from "../SectionDocumentation/Documentation";

export const renderFormStep = (step: number, isMedicareCarveOut: boolean) => {
	switch (step) {
		case 0:
			return <Intro />;
		case 1:
			return <BasicInfo />;
		case 2:
			return <ApplicantInfo />;
		case 3:
			return <Start />;
		case 4:
			return <PremiumPaymentCert />;
		case 5:
			return <Household />;
		case 6:
			return isMedicareCarveOut ? <GrossIncome /> : <Magi />;
		case 7:
			return <OtherIncome />;
		case 8:
			return <AffidavitA />;
		case 9:
			return <AffidavitB />;
		case 10:
			return <AffidavitC />;
		case 11:
			return <Documentation />;
		case 12:
			return <Submission />;
		default:
			return <div>Not Found</div>;
	}
};

export const steps: Step[] = [
	{
		heading: "Low Income Premium Program Application Non-Medicare",
	},
	{
		heading: "Applicant Information",
		subHeading: "Fill in your individual information below.",
	},
	{
		heading: "Applicant Information",
		subHeading:
			"You must be a New Mexico resident to be eligible for the Low Income Premium Program (LIPP).",
	},
	{
		heading: "Household Size & Income",
	},
	{
		heading: "Premium Payment Certification",
		subHeading:
			"You have indicated that your premium payment amount is as follows. Please confirm this to be accurate or make adjustments as needed.",
	},
	{
		heading: "Household Size & Income Verification",
		subHeading:
			"To determine if you qualify for a reduced premium, provide information about your household size and last year's total combined income for all persons over age 18 in your household. Even if only one person is enrolled for NMMIP coverage, you must still provide information about the entire household, since the premium reduction eligibility is based on total household size and income.",
	},
	{
		heading: "Household Size & Income Verification",
	},
	{
		heading: "Other Income Source & Income Change",
		subHeading:
			"Please select YES or NO for every question below. After you answer these three questions you will be directed to the appropriate affidavits.",
	},
	{
		heading: "Federal Tax Form and Affidavit A",
	},
	{
		heading: "Other Income Source and Affidavit B",
	},
	{
		heading: "Income Change Affidavit C",
		subHeading:
			"You have indicated that your current income is different from your most recent tax filing, submit a copy of your Federal Income Tax return AND complete Income Change Affidavit C for eligibility consideration according to your current household income.",
	},
	{
		heading: "Supporting Documentation",
	},
	{
		heading:
			"Thank you for Submitting your Application for Low Income Premium Program Application Non-Medicare",
	},
];

export const medicareCarveOutSteps: Step[] = [
	{
		heading: "Low Income Premium Program Application Medicare Carve-Out",
		subHeading:
			"You have indicated that you meet The Low Income Premium Program (LIPP) eligibility requirements and will be applying for the LIPP Program. A LIPP application is required to be submitted in addition to the application for coverage.",
	},
	{
		heading: "Applicant Information",
		subHeading: "Fill in your individual information below.",
	},
	{
		heading: "Applicant Information",
		subHeading:
			"You must be a New Mexico resident to be eligible for the Low Income Premium Program (LIPP).",
	},
	{
		heading: "Household Size & Income",
	},
	{
		heading: "Premium Payment Certification",
		subHeading:
			"You have indicated that your premium payment amount is as follows. Please confirm this to be accurate or make adjustments as needed.",
	},

	{
		heading: "Household Size & Income Verification",
		subHeading:
			"To determine if you qualify for a reduced premium, provide information about your household size and last year's total combined income for all persons over age 18 in your household. Even if only one person is enrolled for NMMIP coverage, you must still provide information about the entire household, since the premium reduction eligibility is based on total household size and income.",
	},
	{
		heading: "Household Size & Income Verification",
	},
	{
		heading: "Other Income Source & Income Change",
		subHeading:
			"Please select YES or NO for every question below. After you answer these three questions you will be directed to the appropriate affidavits.",
	},
	{
		heading: "Federal Tax Form and Affidavit A",
	},
	{
		heading: "Federal Tax Form and Affidavit B",
	},
	{
		heading: "Income Change Affidavit C",
		subHeading:
			"You have indicated that your current income is different from your most recent tax filing, submit a copy of your Federal Income Tax return AND complete Income Change Affidavit C for eligibility consideration according to your current household income.",
	},
	{
		heading: "Supporting Documentation",
	},
	{
		heading:
			"Thank you for Submitting your Application for Low Income Premium Program Application Medicare Carve-Out",
	},
];

interface Step {
	heading: string;
	subHeading?: string;
}
