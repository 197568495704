import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import FormSelection from "../FormSelection/FormSelection";
import { useAuth } from "../../context/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../Button/Button";
import TextField from "../FormFields/Textfield";
import logo from "../../assets/NMMIP_logo.webp";
import { useFormContext } from "../FormContext/FormContext";

const VerificationForm: React.FC = () => {
  const [step, setStep] = useState<"enterDetails" | "verifyCode" | "verified">(
    "enterDetails"
  );
  const [contactInfo, setContactInfo] = useState<string | null>(null);
  const [verificationMethod, setVerificationMethod] = useState<
    "phone" | "email"
  >("phone");

  const { login } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const apiUrl = process.env.REACT_APP_API_URL;

  const {
    setContextVerificationMethod,
    setContextContactInfo,
    setContextFormId,
  } = useFormContext();

  const phoneValidationSchema = Yup.object({
    phoneNumber: Yup.string()
      .required("Phone number is required")
      .matches(/^\+?[1-9]\d{1,14}$/, "Phone number is not valid"),
  });

  const emailValidationSchema = Yup.object({
    email: Yup.string().email("Invalid email").required("Email is required"),
  });

  const codeValidationSchema = Yup.object({
    code: Yup.string()
      .required("Verification code is required")
      .length(6, "Code must be exactly 6 digits"),
  });

  const handleStartVerification = async (values: {
    phoneNumber?: string;
    email?: string;
  }) => {
    try {
      await axios.post(`${apiUrl}/api/twilio/start-verification`, {
        phoneNumber: values.phoneNumber,
        email: values.email,
      });
      setContactInfo(values.phoneNumber || values.email || "");
      setStep("verifyCode");
    } catch (error) {
      console.error("Failed to start verification", error);
    }
  };

  const handleCheckVerification = async (values: { code: string }) => {
    try {
      const response = await axios.post(
        `${apiUrl}/api/twilio/check-verification`,
        {
          phoneNumber: verificationMethod === "phone" ? contactInfo : undefined,
          email: verificationMethod === "email" ? contactInfo : undefined,
          code: values.code,
        }
      );
      if (response.data.success) {
        setStep("verified");
        login();
        const formResponse = await axios.post(`${apiUrl}/api/forms/update`, {
          verificationMethod,
          contactInfo,
        });

        const formId = formResponse.data.id;

        setContextVerificationMethod(verificationMethod);
        setContextContactInfo(contactInfo);
        setContextFormId(formId);

        await axios.post(`${apiUrl}/api/forms/update`, {
          verificationMethod,
          contactInfo,
        });

        navigate("/home");
      } else {
        alert("Invalid code.");
      }
    } catch (error) {
      console.error("Failed to verify code", error);
      alert("Failed to verify code.");
    }
  };

  return (
    <div className="flex flex-col gap-[1rem] max-w-md mx-auto bg-white p-8 rounded-lg shadow-lg">
      <img
        src={logo}
        alt="NMMIP Logo"
        className="w-1/2 mx-auto cursor-pointer"
        onClick={() => (window.location.href = "https://nmmip.org/")}
      />
      <h1 className="mx-auto text-center text-[24px]">
        New Mexico Medical Insurance Pool (NMMIP) Application for Coverage or
        LIPP Recertification
      </h1>
      <p>
        NMMIP uses a one-time password (OTP) to verify and securely store
        account information. Please login using your phone or email to begin or
        continue an existing application.
      </p>
      {step === "enterDetails" && (
        <div>
          <div className="flex justify-center mb-6">
            <button
              className={`px-4 py-2 rounded-l ${
                verificationMethod === "phone"
                  ? "bg-blue-600 text-white"
                  : "bg-gray-200"
              }`}
              onClick={() => setVerificationMethod("phone")}
            >
              Phone
            </button>
            <button
              className={`px-4 py-2 rounded-r ${
                verificationMethod === "email"
                  ? "bg-blue-600 text-white"
                  : "bg-gray-200"
              }`}
              onClick={() => setVerificationMethod("email")}
            >
              Email
            </button>
          </div>
          {verificationMethod === "phone" && (
            <Formik
              initialValues={{ phoneNumber: "" }}
              validationSchema={phoneValidationSchema}
              onSubmit={(values) =>
                handleStartVerification({ phoneNumber: values.phoneNumber })
              }
            >
              {({ isSubmitting }) => (
                <Form>
                  <TextField
                    name="phoneNumber"
                    label="Phone Number"
                    type="text"
                    required
                  />
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    className="mx-auto !w-full justify-center mt-[1rem]"
                  >
                    Send Verification Code
                  </Button>
                </Form>
              )}
            </Formik>
          )}
          {verificationMethod === "email" && (
            <Formik
              initialValues={{ email: "" }}
              validationSchema={emailValidationSchema}
              onSubmit={(values) =>
                handleStartVerification({ email: values.email })
              }
            >
              {({ isSubmitting }) => (
                <Form>
                  <TextField
                    name="email"
                    label="Email Address"
                    type="text"
                    required
                  />
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    className="mx-auto !w-full justify-center mt-[1rem]"
                  >
                    Send Verification Code
                  </Button>
                </Form>
              )}
            </Formik>
          )}
        </div>
      )}

      {step === "verifyCode" && (
        <Formik
          initialValues={{ code: "" }}
          validationSchema={codeValidationSchema}
          onSubmit={handleCheckVerification}
        >
          {({ isSubmitting }) => (
            <Form>
              <TextField
                name="code"
                type="text"
                label="Verification Code"
                required
              />
              <Button
                className="mx-auto !w-full justify-center mt-[1rem]"
                type="submit"
                disabled={isSubmitting}
              >
                Verify Code
              </Button>
            </Form>
          )}
        </Formik>
      )}

      {step === "verified" && contactInfo && (
        <FormSelection
          verificationMethod={verificationMethod}
          contactInfo={contactInfo}
        />
      )}

      {/* <Button
				type="button"
				onClick={() => {
					login();
					navigate("/healthcare-coverage-form");
				}}
			>
				Bypass login to basic coverage form
			</Button>
			<Button
				type="button"
				onClick={() => {
					login();

					navigate("/lipp-form/", {
						state: {
							basicApp: {
								applicant: {
									firstName: "John",
									lastName: "Doe",
									middleInitial: "M",
									homePhone: "1234567890",
									workPhone: "9876543210",
									cellPhone: "4564564560",
									email: "johndoe@gmail.com",
								},
								residence: {
									city: "Los Alamos",
									state: "New Mexico",
									zip: "87544",
									address: "3332 Cooks Mine Road",
								},
								coverageStartMonth: "December",
								coverageStartYear: "2024",
								county: "Bernalillo, Sandoval, Torrance, Valencia Counties",
								householdSize: "3",
								householdAnnualIncome: "74331",
								deductible: "$500",
							},
						},
					});
				}}
			>
				Bypass login to lipp non-medicare
			</Button>
			<Button
				type="button"
				onClick={() => {
					login();

					navigate("/lipp-form/medicare-carve-out", {
						state: {
							basicApp: {
								applicant: {
									firstName: "John",
									lastName: "Doe",
									middleInitial: "M",
									homePhone: "1234567890",
									workPhone: "9876543210",
									cellPhone: "4564564560",
									email: "johndoe@gmail.com",
								},
								residence: {
									city: "Los Alamos",
									state: "New Mexico",
									zip: "87544",
									address: "3332 Cooks Mine Road",
								},
								coverageStartMonth: "December",
								coverageStartYear: "2024",
								county: "Bernalillo, Sandoval, Torrance, Valencia Counties",
								householdSize: "3",
								householdAnnualIncome: "74331",
								deductible: "$500",
							},
						},
					});
				}}
			>
				Bypass login to lipp medicare carve-out
			</Button>
			<Button
				type="button"
				onClick={() => {
					login();
					navigate("/medicare-form/");
				}}
			>
				Bypass login to medicare
			</Button>
			<Button
				type="button"
				onClick={() => {
					login();
					navigate("/newborn-form/");
				}}
			>
				Bypass login to newborn
			</Button> */}
    </div>
  );
};

export default VerificationForm;
