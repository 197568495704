import Dropdown from "../../../components/FormFields/Dropdown";
import CheckBox from "../../../components/FormFields/CheckBox";
import TextField from "../../../components/FormFields/Textfield";
import Radio from "../../../components/FormFields/Radio";
import DateField from "../../../components/FormFields/DateField";
import Signature from "../../../components/FormFields/Signature";
import FileUpload, {
	FileObj,
	UploadedFile,
	UploadedFileList,
} from "../../../components/FormFields/FileUpload";
import Modal from "../../../components/Modal/Modal";
import Button, { ColorStyle } from "../../../components/Button/Button";
import NoticeBanner from "../../../components/NoticeBanner/NoticeBanner";
import * as Yup from "yup";
import FormGrid from "../../../components/FormGrid/FormGrid";
import { useFormikContext } from "formik";
import { getNestedValue } from "../../../utils/getNestedValue";
import { useEffect, useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import { normalizeDate } from "../../../utils/normalizeDate";
import { FaCloudUploadAlt, FaTimesCircle } from "react-icons/fa";
import { useFormContext } from "../../../components/FormContext/FormContext";
import { useAdminSettings } from "../../../components/AdminSettingsProvider/AdminSettingsProvider";
import { PaymentTypes } from "../../HealthCareCoverageForm/FormSchema/formInitialValues";

const requiredWarning = "This field is required.";

export const schema = {
	values: {},
	validation: Yup.object().shape({}),
};

const Documentation = () => {
	const { errors, values, setFieldValue } = useFormikContext<any>();
	const { formId } = useFormContext(); // Get formId from context
	const isACH =
		values["paymentType"] === PaymentTypes[1].label ||
		values["paymentType"] === PaymentTypes[2].label;
	const { settings } = useAdminSettings();

	return (
		<>
			<div className="flex flex-col gap-[30px]">
				<p>
					To apply for New Mexico Medical Insurance Pool (NMMIP) coverage you
					will need to submit the following supporting documentation.
					<br /> <br />
					If you don't have the documentation electronically, please mail it to
					NMMIP upon completing this application.
				</p>
				<p className="leading-[1.5rem]">
					<strong>Mail Supporting Documentation to:</strong> <br />
					<span
						dangerouslySetInnerHTML={{
							__html:
								settings?.supporting_documentation_address ||
								`New Mexico Medical Insurance Pool (NMMIP) <br />
								PO Box 780548 <br />
								San Antonio, TX 78278`,
						}}
					></span>
				</p>

				<div>
					<h2 className="mb-[1rem]">Proof of Residency Documentation</h2>
					<div className="max-w-[500px] mb-[1rem]">
						<UploadedFileList
							formId={formId}
							fieldName={"documentation.proofOfResidency"}
							documentationType="proofOfResidency"
						/>
					</div>
					<Modal
						modalLabel="Proof of Residency Documentation"
						buttonLabel="Upload Documentation"
					>
						<NoticeBanner bannerstyle={ColorStyle.Primary}>
							You have indicated that you are a resident of the State of New
							Mexico. Please provide Proof of Residency Documentation (lease
							agreement, mortgage statement, utility bill, or other document).
						</NoticeBanner>
						<NoticeBanner bannerstyle={ColorStyle.Secondary}>
							If you don't have the documentation electronically, please mail it
							to NMMIP upon completing this application.
						</NoticeBanner>
						<FileUpload
							name="documentation.proofOfResidency"
							label="Upload Documentation"
							documentationType="proofOfResidency"
						/>
					</Modal>
				</div>

				<div>
					<h2 className="mb-[1rem]">Award Letter or Medicare Card</h2>
					<div className="max-w-[500px] mb-[1rem]">
						<UploadedFileList
							formId={formId}
							fieldName={"documentation.awardLetterOrMedicareCard"}
							documentationType={"awardLetterOrMedicareCard"}
						/>
					</div>
					<Modal
						modalLabel="Award Letter or Medicare Card"
						buttonLabel="Upload Documentation"
					>
						<NoticeBanner bannerstyle={ColorStyle.Primary}>
							A copy of your award letter or Medicare Card is required with this
							application.
						</NoticeBanner>
						<NoticeBanner bannerstyle={ColorStyle.Secondary}>
							If you don't have the documentation electronically, please mail it
							to NMMIP upon completing this application.
						</NoticeBanner>
						<FileUpload
							name="documentation.awardLetterOrMedicareCard"
							label="Upload Documentation"
							documentationType="awardLetterOrMedicareCard"
						/>
					</Modal>
				</div>

				{isACH && (
					<div>
						<h2 className="mb-[1rem]">Voided Check</h2>
						<div className="max-w-[500px] mb-[1rem]">
							<UploadedFileList
								formId={formId}
								fieldName={"documentation.voidedCheck"}
								documentationType="voidedCheck"
							/>
						</div>
						<Modal modalLabel="Voided Check" buttonLabel="Upload Voided Check">
							<NoticeBanner bannerstyle={ColorStyle.Primary}>
								You'll need to upload a voided check. A deposit ticket will not
								be accepted.
							</NoticeBanner>
							<NoticeBanner bannerstyle={ColorStyle.Secondary}>
								If you don't have the documentation electronically, please mail
								it to NMMIP upon completing this application.
							</NoticeBanner>
							<FileUpload
								name="documentation.voidedCheck"
								label="Upload Files"
								documentationType="voidedCheck"
							/>
						</Modal>
					</div>
				)}

				<div>
					<h2 className="mb-[1rem]">Other Relevant Documents</h2>
					<div className="max-w-[500px] mb-[1rem]">
						<UploadedFileList
							formId={formId}
							fieldName={"documentation.medicareAdditionalDocumentation"}
							documentationType="medicareAdditionalDocumentation"
						/>
					</div>
					<Modal
						modalLabel="Other Relevant Documents"
						buttonLabel="Upload Documentation"
					>
						<NoticeBanner bannerstyle={ColorStyle.Primary}>
							Upload other relevant documents as applicable.
						</NoticeBanner>
						<NoticeBanner bannerstyle={ColorStyle.Secondary}>
							If you don't have the documentation electronically, please mail it
							to NMMIP upon completing this application.
						</NoticeBanner>
						<FileUpload
							name="documentation.medicareAdditionalDocumentation"
							label="Upload Documentation"
							documentationType="medicareAdditionalDocumentation"
						/>
					</Modal>
				</div>
			</div>
		</>
	);
};

export default Documentation;
