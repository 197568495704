import Dropdown from "../../../components/FormFields/Dropdown";
import CheckBox from "../../../components/FormFields/CheckBox";
import TextField from "../../../components/FormFields/Textfield";
import Radio from "../../../components/FormFields/Radio";
import DateField from "../../../components/FormFields/DateField";
import Signature from "../../../components/FormFields/Signature";
import FileUpload, {
	UploadedFileList,
} from "../../../components/FormFields/FileUpload";
import Modal from "../../../components/Modal/Modal";
import Button, { ColorStyle } from "../../../components/Button/Button";
import NoticeBanner from "../../../components/NoticeBanner/NoticeBanner";
import * as Yup from "yup";
import FormGrid from "../../../components/FormGrid/FormGrid";
import { useFormikContext } from "formik";
import { useEffect } from "react";
import { getNestedValue } from "../../../utils/getNestedValue";
import { useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { USStates } from "../../HealthCareCoverageForm/FormSchema/formInitialValues";
import { useFormContext } from "../../../components/FormContext/FormContext";

const requiredWarning = "This field is required.";

export const schema = {
	values: {
		applicant: {
			lastName: "",
			firstName: "",
			middleInitial: "",
			birthDate: "",
			gender: "",
			ssn: "",
			homePhone: "",
			workPhone: "",
			cellPhone: "",
			email: "",
			preferredCommunication: "",
			preferredLanguage: "",
			isHearingImpaired: "",
		},
		residence: {
			address: "",
			city: "",
			state: "New Mexico",
			zipCode: "",
			county: "",
		},
		mailing: {
			address: "",
			city: "",
			state: "",
			zipCode: "",
			county: "",
		},
	},
	validation: Yup.object().shape({
		applicant: Yup.object().shape({
			lastName: Yup.string().required(requiredWarning),
			firstName: Yup.string().required(requiredWarning),
			birthDate: Yup.string().required(requiredWarning),
			gender: Yup.string().required(requiredWarning),
			// Custom validation to ensure at least one phone number is provided
			atLeastOnePhoneNumberIsRequired: Yup.mixed().test({
				name: "atLeastOnePhoneNumber",
				message: "At least one phone number is required.",
				test(value, ctx) {
					const { homePhone, workPhone, cellPhone } = ctx.parent;
					return homePhone || workPhone || cellPhone;
				},
			}),
			email: Yup.string().email("Invalid email format"),
			// .required(requiredWarning),
		}),
		residence: Yup.object().shape({
			address: Yup.string().required(requiredWarning),
			city: Yup.string().required(requiredWarning),
			state: Yup.string()
				.oneOf(["New Mexico"], "You must be a New Mexico resident to apply.")
				.required(requiredWarning),
			zipCode: Yup.string().required(requiredWarning),
			county: Yup.string().required(requiredWarning),
		}),
	}),
};

const ApplicantInfo = () => {
	const { values, errors } = useFormikContext<any>();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const residenceState = values["residence"]["state"];
	const { formId } = useFormContext(); // Get formId from context

	const phoneErr = (errors as PartialErrors).applicant
		?.atLeastOnePhoneNumberIsRequired;

	useEffect(() => {
		if (residenceState && residenceState !== "New Mexico") {
			setIsModalOpen(true);
		} else {
			setIsModalOpen(false);
		}
	}, [residenceState]);

	return (
		<>
			<p>
				To select your birth year, find the date in the calendars OR click the
				current month and year at the top of the calendar, then click the year
				to view by year. Clicking a third time will allow you to first select a
				decade.
			</p>
			<h2>Basic Information</h2>
			<FormGrid>
				<TextField
					type="text"
					name="applicant.lastName"
					label="Last Name"
					required
				/>
				<TextField
					type="text"
					name="applicant.firstName"
					label="First Name"
					required
				/>
				<TextField
					className="sm:!max-w-[175px]"
					type="text"
					name="applicant.middleInitial"
					label="Middle Initial"
				/>
			</FormGrid>
			<FormGrid>
				<DateField
					className="!basis-[275px]"
					name="applicant.birthDate"
					label="Birth Date (MM/DD/YYYY)"
					placeholder="Birth Date (MM/DD/YYYY)"
					required
				/>
				<Dropdown
					name="applicant.gender"
					label="Gender"
					options={[{ label: "Female" }, { label: "Male" }]}
					required
				/>
				<TextField
					type="text"
					name="applicant.ssn"
					label="Social Security Number (if applicable)"
					maxLength={9}
					mobileExtraPadding
				/>
			</FormGrid>

			<h2>Contact Information</h2>

			<FormGrid>
				<TextField
					className="!basis-[400px]"
					type="text"
					name="residence.address"
					label="Residence Address (Physical address required)"
					required
					autoComplete="street-address"
					mobileExtraPadding
				/>
				<TextField
					className="!xs:max-w-[200px]"
					type="text"
					name="residence.city"
					label="City"
					required
				/>
				<Dropdown
					className="!xs:max-w-[200px]"
					name="residence.state"
					label="State"
					options={USStates}
					required
				/>
				<TextField
					className="!xs:max-w-[200px]"
					type="text"
					name="residence.zipCode"
					label="Zip Code"
					required
					maxLength={5}
				/>
				<TextField
					className="!xs:max-w-[200px]"
					type="text"
					name="residence.county"
					label="County"
					required
				/>
			</FormGrid>

			<FormGrid>
				<TextField
					className="!basis-[400px]"
					type="text"
					name="mailing.address"
					label="Mailing Address (if different than Residence)"
					autoComplete="off"
					mobileExtraPadding
				/>
				<TextField
					type="text"
					name="mailing.city"
					label="City"
					autoComplete="off"
				/>
				<Dropdown name="mailing.state" label="State" options={USStates} />
				<TextField
					type="text"
					name="mailing.zipCode"
					label="Zip Code"
					maxLength={5}
					autoComplete="off"
				/>
				<TextField
					type="text"
					name="mailing.county"
					label="County"
					autoComplete="off"
				/>
			</FormGrid>

			<FormGrid>
				<TextField
					className="!basis-[275px]"
					type="email"
					name="applicant.email"
					label="Email Address"
				/>
				<TextField
					type="text"
					name="applicant.homePhone"
					label="Home Phone"
					error={phoneErr}
				/>
				<TextField
					type="text"
					name="applicant.workPhone"
					label="Work Phone"
					error={phoneErr}
				/>
				<TextField
					type="text"
					name="applicant.cellPhone"
					label="Cell Phone"
					error={phoneErr}
				/>
			</FormGrid>

			<Dropdown
				className="!max-w-[400px]"
				name="applicant.preferredCommunication"
				label="Select Preferred Method of Communication"
				options={[{ label: "Phone" }, { label: "Text" }, { label: "Email" }]}
			/>

			<Modal
				modalLabel="Proof of Residency Documentation"
				buttonLabel="Upload Documentation"
			>
				<NoticeBanner bannerstyle={ColorStyle.Primary}>
					You have indicated that you are a resident of the State of New Mexico.
					Please provide Proof of Residency Documentation (lease agreement,
					mortgage statement, utility bill, voter registration, bank or credit
					card statement, or another document).
				</NoticeBanner>
				<NoticeBanner bannerstyle={ColorStyle.Secondary}>
					If you don't have the documentation electronically, please mail it to
					NMMIP upon completing this application.
				</NoticeBanner>
				<FileUpload
					name="documentation.proofOfResidency"
					label="Upload Documentation"
					documentationType="proofOfResidency"
				/>
			</Modal>

			<div className="max-w-[500px]">
				<UploadedFileList
					formId={formId}
					fieldName={"documentation.proofOfResidency"}
					documentationType="proofOfResidency"
				/>
			</div>

			<Modal
				modalLabel="Residency Eligibility"
				open={isModalOpen}
				setOpen={setIsModalOpen}
			>
				<NoticeBanner bannerstyle={ColorStyle.Secondary}>
					You have indicated that you are <span className="underline">not</span>{" "}
					a resident of the State of New Mexico and therefore do not qualify for
					health insurance under NMMIP's eligbility guidelines. You{" "}
					<span className="underline">must</span> be a New Mexico resident to
					apply.
				</NoticeBanner>
				<Button
					className="mx-auto"
					type="button"
					onClick={() => setIsModalOpen(false)}
				>
					<FaArrowLeft />
					Back
				</Button>
			</Modal>
		</>
	);
};

export default ApplicantInfo;

interface PartialErrors {
	applicant?: {
		atLeastOnePhoneNumberIsRequired?: string;
	};
}
