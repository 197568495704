// section intro
import { schema as introSchema } from "../SectionIntro/Intro";
import { schema as startSchema } from "../SectionIntro/Start";

// section 1
import { schema as basicInfoSchema } from "../Section1/BasicInfo";
import { schema as applicantInfoSchema } from "../Section1/ApplicantInfo";

// section 2
import { schema as premiumpaymentCertSchema } from "../Section1/PremiumPaymentCert";

// section 3
import { schema as householdSchema } from "../Section2/Household";
import { schema as magiSchema } from "../Section2/Magi";
import { schema as grossIncomeSchema } from "../Section2/GrossIncome";

// section 4
import { schema as otherIncomeSchema } from "../Section3/OtherIncome";

// section 5
import { schema as affidavitASchema } from "../Section4/AffidavitA";
import { schema as affidavitBSchema } from "../Section4/AffidavitB";
import { schema as affidavitCSchema } from "../Section5/AffidavitC";

// section documentation
import { schema as documentationSchema } from "../SectionDocumentation/Documentation";

export const countyOptions = [
	{ label: "Bernalillo, Sandoval, Torrance, Valencia Counties" },
	{ label: "San Juan County" },
	{ label: "Dona Ana County" },
	{ label: "Santa Fe County" },
	{ label: "All Other Counties" },
];

export const householdSizeOptions = [
	{ label: "One", value: 1 },
	{ label: "Two", value: 2 },
	{ label: "Three", value: 3 },
	{ label: "Four", value: 4 },
	{ label: "Five", value: 5 },
	{ label: "Six", value: 6 },
];

export const povertyGuidelineOptions = [
	{ threshold: "0-199%", reduction: 75 },
	{ threshold: "200-299%", reduction: 50 },
	{ threshold: "300-399%", reduction: 25 },
];

// returns arr of 3 income thresholds based on household size
export const incomeThresholdMap2024 = (householdSize: number): number[] => {
	switch (householdSize) {
		case 1:
			// e.g.
			// $0-$29,014
			// $29,015-$43,594
			// $43,595-$58,174
			return [29014, 43594, 58174];
		case 2:
			return [39243, 58963, 78683];
		case 3:
			return [49471, 74331, 99191];
		case 4:
			return [59700, 89700, 119700];
		case 5:
			return [69929, 105069, 140209];
		case 6:
			return [80157, 120437, 160717];
		default:
			return [];
	}
};

export const incomeThresholdMap2025 = (householdSize: number): number[] => {
	switch (householdSize) {
		case 1:
			return [30199, 45179, 60239];
		case 2:
			return [40883, 61319, 81767];
		case 3:
			return [51647, 77459, 103283];
		case 4:
			return [62399, 93599, 124799];
		case 5:
			return [73163, 109739, 146327];
		case 6:
			return [83927, 125879, 167843];
		default:
			return [];
	}
};

export type Schema = {
	values: Record<string, any>;
	validation: any;
};

export const schemas: Record<string, Schema> = {
	basicInfoSchema,
	applicantInfoSchema,
	startSchema,
	premiumpaymentCertSchema,
	householdSchema,
	magiSchema,
	otherIncomeSchema,
	affidavitASchema,
	affidavitBSchema,
	affidavitCSchema,
	documentationSchema,
};

export const medicareCarveOutSchemas: Record<string, Schema> = {
	basicInfoSchema,
	applicantInfoSchema,
	startSchema,
	premiumpaymentCertSchema,
	householdSchema,
	grossIncomeSchema,
	otherIncomeSchema,
	affidavitASchema,
	affidavitBSchema,
	affidavitCSchema,
	documentationSchema,
};

export const lippFormValues = {
	...basicInfoSchema.values,
	...applicantInfoSchema.values,
	...startSchema.values,
	...premiumpaymentCertSchema.values,
	...householdSchema.values,
	...magiSchema.values,
	...otherIncomeSchema.values,
	...affidavitASchema.values,
	...affidavitBSchema.values,
	...affidavitCSchema.values,
	...documentationSchema.values,
};

export const lippFormSchema = [
	introSchema.validation,
	basicInfoSchema.validation,
	applicantInfoSchema.validation,
	startSchema.validation,
	premiumpaymentCertSchema.validation,
	householdSchema.validation,
	magiSchema.validation,
	otherIncomeSchema.validation,
	affidavitASchema.validation,
	affidavitBSchema.validation,
	affidavitCSchema.validation,
	documentationSchema.validation,
];

export const medicareLippFormValues = {
	...basicInfoSchema.values,
	...applicantInfoSchema.values,
	...startSchema.values,
	...premiumpaymentCertSchema.values,
	...householdSchema.values,
	...grossIncomeSchema.values,
	...otherIncomeSchema.values,
	...affidavitASchema.values,
	...affidavitBSchema.values,
	...affidavitCSchema.values,
	...documentationSchema.values,
};

export const medicareLippFormSchema = [
	introSchema.validation,
	basicInfoSchema.validation,
	applicantInfoSchema.validation,
	startSchema.validation,
	premiumpaymentCertSchema.validation,
	householdSchema.validation,
	grossIncomeSchema.validation,
	otherIncomeSchema.validation,
	affidavitASchema.validation,
	affidavitBSchema.validation,
	affidavitCSchema.validation,
	documentationSchema.validation,
];
