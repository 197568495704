import Dropdown from "../../../components/FormFields/Dropdown";
import CheckBox from "../../../components/FormFields/CheckBox";
import TextField from "../../../components/FormFields/Textfield";
import Radio from "../../../components/FormFields/Radio";
import DateField from "../../../components/FormFields/DateField";
import Signature from "../../../components/FormFields/Signature";
import FileUpload, {
	UploadedFileList,
} from "../../../components/FormFields/FileUpload";
import Modal from "../../../components/Modal/Modal";
import Button, { ColorStyle } from "../../../components/Button/Button";
import NoticeBanner from "../../../components/NoticeBanner/NoticeBanner";
import * as Yup from "yup";
import FormGrid from "../../../components/FormGrid/FormGrid";
import { useFormikContext } from "formik";
import { getNestedValue } from "../../../utils/getNestedValue";
import { useFormContext } from "../../../components/FormContext/FormContext";

const requiredWarning = "This field is required.";

export const schema = {
	values: {
		applicant: {
			lastName: "",
			firstName: "",
			middleInitial: "",
			birthDate: "",
			gender: "",
			ssn: "",
			homePhone: "",
			workPhone: "",
			cellPhone: "",
			email: "",
			preferredCommunication: "",
			preferredAdditionalContact: "",
			poaAgencyName: "",
			poaAddress: "",
			poaPhone: "",
			emergencyContactName: "",
			emergencyContactAddress: "",
			emergencyContactPhone: "",
		},
	},
	validation: Yup.object().shape({
		applicant: Yup.object().shape({
			lastName: Yup.string().required(requiredWarning),
			firstName: Yup.string().required(requiredWarning),
			birthDate: Yup.string().required(requiredWarning),
			gender: Yup.string().required(requiredWarning),
			// Custom validation to ensure at least one phone number is provided
			atLeastOnePhoneNumberIsRequired: Yup.mixed().test({
				name: "atLeastOnePhoneNumber",
				message: "At least one phone number is required.",
				test(value, ctx) {
					const { homePhone, workPhone, cellPhone } = ctx.parent;
					return homePhone || workPhone || cellPhone;
				},
			}),
			email: Yup.string().email("Invalid email format"),
			// .required(requiredWarning),
			preferredAdditionalContact: Yup.string().required(requiredWarning),
			poaAgencyName: Yup.string().test({
				name: "requiredAgency",
				message: requiredWarning,
				test(value, ctx) {
					const { preferredAdditionalContact } = ctx.parent;
					return preferredAdditionalContact === "Power of Attorney"
						? value
							? true
							: false
						: true;
				},
			}),
			poaAddress: Yup.string().test({
				name: "requiredAddress",
				message: requiredWarning,
				test(value, ctx) {
					const { preferredAdditionalContact } = ctx.parent;
					return preferredAdditionalContact === "Power of Attorney"
						? value
							? true
							: false
						: true;
				},
			}),
			poaPhone: Yup.string().test({
				name: "requiredPhone",
				message: requiredWarning,
				test(value, ctx) {
					const { preferredAdditionalContact } = ctx.parent;
					return preferredAdditionalContact === "Power of Attorney"
						? value
							? true
							: false
						: true;
				},
			}),
			emergencyContactName: Yup.string().test({
				name: "requiredEmergencyContactName",
				message: requiredWarning,
				test(value, ctx) {
					const { preferredAdditionalContact } = ctx.parent;
					return preferredAdditionalContact === "Emergency Contact"
						? value
							? true
							: false
						: true;
				},
			}),
			emergencyContactAddress: Yup.string().test({
				name: "requiredEmergencyContactAddress",
				message: requiredWarning,
				test(value, ctx) {
					const { preferredAdditionalContact } = ctx.parent;
					return preferredAdditionalContact === "Emergency Contact"
						? value
							? true
							: false
						: true;
				},
			}),
			emergencyContactPhone: Yup.string().test({
				name: "requiredEmergencyContactPhone",
				message: requiredWarning,
				test(value, ctx) {
					const { preferredAdditionalContact } = ctx.parent;
					return preferredAdditionalContact === "Emergency Contact"
						? value
							? true
							: false
						: true;
				},
			}),
		}),
	}),
};

const BasicInfo = () => {
	const { errors, values } = useFormikContext<any>();
	const phoneErr = (errors as PartialErrors).applicant
		?.atLeastOnePhoneNumberIsRequired;
	const preferredAdditionalContact =
		values["applicant"]["preferredAdditionalContact"];

	const { formId } = useFormContext(); // Get formId from context

	return (
		<>
			<p>
				To select your birth year, find the date in the calendars OR click the
				current month and year at the top of the calendar, then click the year
				to view by year. Clicking a third time will allow you to first select a
				decade.
			</p>
			<h2>Basic Information</h2>
			<FormGrid>
				<TextField
					type="text"
					name="applicant.lastName"
					label="Last Name"
					required
				/>
				<TextField
					type="text"
					name="applicant.firstName"
					label="First Name"
					required
				/>
				<TextField
					className="sm:!max-w-[175px]"
					type="text"
					name="applicant.middleInitial"
					label="Middle Initial"
				/>
			</FormGrid>
			<FormGrid>
				<DateField
					className="!basis-[275px]"
					name="applicant.birthDate"
					label="Birth Date (MM/DD/YYYY)"
					placeholder="Birth Date (MM/DD/YYYY)"
					required
				/>
				<Dropdown
					name="applicant.gender"
					label="Gender"
					options={[{ label: "Female" }, { label: "Male" }]}
					required
				/>
				<TextField
					type="text"
					name="applicant.ssn"
					label="Social Security Number (if applicable)"
					maxLength={9}
					mobileExtraPadding
				/>
			</FormGrid>

			<h2>Contact Information</h2>

			<FormGrid>
				<TextField
					type="text"
					name="applicant.homePhone"
					label="Home Phone"
					error={phoneErr}
				/>
				<TextField
					type="text"
					name="applicant.workPhone"
					label="Work Phone"
					error={phoneErr}
				/>
				<TextField
					type="text"
					name="applicant.cellPhone"
					label="Cell Phone"
					error={phoneErr}
				/>
				<TextField
					className="!basis-[275px]"
					type="email"
					name="applicant.email"
					label="Email Address"
				/>
				<Dropdown
					className="!basis-[400px]"
					name="applicant.preferredCommunication"
					label="Select Preferred Method of Communication"
					options={[{ label: "Phone" }, { label: "Text" }, { label: "Email" }]}
				/>
				<Dropdown
					className="!basis-[400px]"
					name="applicant.preferredAdditionalContact"
					label="Select Preferred Additional Contact Person"
					options={[
						{ label: "Emergency Contact" },
						{ label: "Power of Attorney" },
					]}
					required
				/>
			</FormGrid>

			{preferredAdditionalContact === "Power of Attorney" && (
				<>
					<h2>Power of Attorney Information</h2>

					<p>If POA, attach documentation.</p>
					<FormGrid>
						<TextField
							type="text"
							name="applicant.poaAgencyName"
							label="Individual or Agent Name"
							required
						/>
						<TextField
							type="text"
							name="applicant.poaAddress"
							label="Address"
							required
						/>
						<TextField
							className="!xs:max-w-[200px]"
							type="text"
							name="applicant.poaPhone"
							label="Phone Number"
							required
						/>
					</FormGrid>
					<Modal
						modalLabel="Power of Attorney Documentation"
						buttonLabel="Upload Documentation"
					>
						<NoticeBanner bannerstyle={ColorStyle.Primary}>
							You have indicated that you will be using a Power of Attorney
							(POA). Please provide Power of Attorney documentation.
						</NoticeBanner>
						<NoticeBanner bannerstyle={ColorStyle.Secondary}>
							If you don't have the documentation electronically, please mail it
							to NMMIP upon completing this application.
						</NoticeBanner>
						<FileUpload
							documentationType="poa"
							name="documentation.poa"
							label="Upload Documentation"
						/>
					</Modal>
					<div className="max-w-[500px]">
						<UploadedFileList
							formId={formId}
							fieldName={"documentation.poa"}
							documentationType="poa"
						/>
					</div>
				</>
			)}

			{preferredAdditionalContact === "Emergency Contact" && (
				<>
					<h2>Emergency Contact Information</h2>

					<FormGrid>
						<TextField
							type="text"
							name="applicant.emergencyContactName"
							label="Individual Name"
							required
						/>
						<TextField
							type="text"
							name="applicant.emergencyContactAddress"
							label="Address"
							required
						/>
						<TextField
							className="!xs:max-w-[200px]"
							type="text"
							name="applicant.emergencyContactPhone"
							label="Phone Number"
							required
						/>
					</FormGrid>
				</>
			)}
		</>
	);
};

export default BasicInfo;

interface PartialErrors {
	applicant?: {
		atLeastOnePhoneNumberIsRequired?: string;
	};
}
